<template>
  <v-layout column>
    <v-hover>
      <v-layout
        slot-scope="{ hover }"
        :style="{
          cursor: 'pointer',
          backgroundColor: hover ? '#F5FAFE' : hasPairs ? '#F9F9F9' : 'white',
        }"
        px-4
        py-1
        align-center
      >
        <v-layout align-center>
          <DefaultButton
            icon
            small
            bgColor="transparent"
            color="primary"
            class="my-0 mr-1 ml-0 px-0 py-0"
            v-if="!noPairs && record.chained"
            :loading="loading"
            @click.prevent="handleSelection"
          >
            <v-icon small>mdi-link</v-icon>
          </DefaultButton>
          <v-layout align-center @click.prevent="handleSingle">
            <v-flex
              :class="
                !noPairs && record.chained ? 'text-style-chained' : 'text-style'
              "
            >
              <DefaultLabel class="text-truncate">{{ record.title }}</DefaultLabel>
            </v-flex>
            <v-spacer></v-spacer>
          </v-layout>
        </v-layout>
        <v-flex shrink>
          <v-checkbox
            v-if="internalSelectedRecords.length > 1"
            class="pa-0 ma-0 zg-prmd-checkbox-style"
            v-model="selectedRecord"
            hide-details
            :on-icon="icon"
            :ripple="false"
          ></v-checkbox>
          <v-checkbox
            v-if="internalSelectedRecords.length <= 1"
            v-model="selectedRecord"
            class="pa-0 ma-0 zg-prmd-checkbox-style"
            hide-details
            :ripple="false"
          >
          </v-checkbox>
        </v-flex>
      </v-layout>
    </v-hover>
    <PredefinedPairMenuDelegate
      v-for="pair in pairs"
      :key="pair.altId"
      :value="pair"
      :selectedPairs.sync="selectedPairs"
    ></PredefinedPairMenuDelegate>
    <v-flex v-if="hasPairs" class="pairs-bg px-4 pb-2">
      <v-switch
        class="zg-text-control ml-4 my-0"
        label="Dodaj sparowane wpisy"
        color="expressive_green"
        v-model="addPairs"
        hideDetails
        :ripple="false"
        inset
      ></v-switch>
    </v-flex>
  </v-layout>
</template>

<script>
import PredefinedService from "@/services/predefined.service";
import CRUDMixin from "@/mixins/CRUDMixin";
import _ from "lodash";

export default {
  mixins: [CRUDMixin],
  props: {
    record: {},
    selectedRecords: {
      type: Array,
    },
    noPairs: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedRecord: false,
      pairs: {},
      selectedPairs: [],
      addPairs: true,
    };
  },
  watch: {
    selectedPairs() {
      console.log(this.internalSelectedRecords);
      if (this.selectedRecord) {
        this.updateSelection();
      } else {
        this.removeSelection();
      }
    },
    selectedRecord() {
      console.log(this.internalSelectedRecords);
      if (this.selectedRecord) {
        this.updateSelection();
      } else {
        this.removeSelection();
      }
    },
    addPairs() {
      if (this.addPairs) {
        this.updatePairsSelection();
      } else {
        this.removePairsSelection();
      }
    },
  },
  computed: {
    selection() {
      let fields = [];
      if (this.hasPairs) {
        this.selectedPairs.forEach((element) => {
          const pair = this.pairs[element];
          if (pair) {
            fields.push(pair);
          }
        });
      }
      return { id: this.record.id, fields: fields };
    },
    altId() {
      return `0_${this.record.id}`;
    },
    internalSelectedRecords: {
      get() {
        return this.selectedRecords;
      },
      set(value) {
        this.$emit("update:selectedRecords", value);
      },
    },
    selectionIndex() {
      const index = _.findIndex(this.internalSelectedRecords, {
        id: this.record.id,
      });
      if (index === -1) {
        return 1;
      } else {
        return index + 1;
      }
    },
    icon() {
      if (this.selectionIndex <= 9) {
        return `mdi-numeric-${this.selectionIndex}-circle`;
      } else {
        return "mdi-numeric-9-plus-circle";
      }
    },
    hasPairs() {
      return Object.keys(this.pairs).length > 0;
    },
  },
  components: {
    DefaultButton: () => import("@/components/buttons/DefaultButton"),
    DefaultLabel: () => import("@/components/text/DefaultLabel"),
    PredefinedPairMenuDelegate: () =>
      import("@/components/delegates/predefined/PredefinedPairMenuDelegate"),
  },
  methods: {
    pairKey(pair) {
      return `${pair.category}_${pair.id}`;
    },
    updateSelection() {
      const index = _.findIndex(this.internalSelectedRecords, {
        id: this.record.id,
      });
      if (index !== -1) {
        this.internalSelectedRecords.splice(index, 1, this.selection);
      } else {
        this.internalSelectedRecords.push(this.selection);
      }
    },
    removeSelection() {
      const index = _.findIndex(this.internalSelectedRecords, {
        id: this.record.id,
      });
      if (index !== -1) {
        this.internalSelectedRecords.splice(index, 1);
      }
    },
    updatePairsSelection() {
      Object.keys(this.pairs).forEach((key) => {
        this.selectedPairs.push(this.pairKey(this.pairs[key]));
      });
    },
    removePairsSelection() {
      this.selectedPairs = [];
    },
    handleSingle() {
      this.selectedRecord = true;
      this.selectedPairs = [this.altId];
      this.$nextTick(() => this.$emit("single"));
    },
    handleSelection() {
      if (!this.noPairs && this.record.chained) {
        if (!this.hasPairs) {
          this.fetch();
        } else {
          this.pairs = {};
          this.selectedPairs = [];
          this.selectedRecord = false;
        }
      } else {
        this.handleSingle();
      }
    },
    fetch() {
      this.beforeRequest();
      PredefinedService.getPredefinedPairs(this.record.id)
        .then((reply) => {
          this.selectedPairs = [this.altId];
          this.pairs = {};
          reply.fields.forEach((element) => {
            const key = this.pairKey(element);
            this.pairs[key] = element;
            this.selectedPairs.push(key);
          });
          this.selectedRecord = true;
          this.reset();
        })
        .catch((error) => {
          console.log("ERROR", error, error.reply);
        });
    },
  },
};
</script>

<style lang="scss">
.zg-prmd-checkbox-style .v-input--selection-controls__input {
  margin-right: 0px !important;
}
</style>

<style lang="scss" scoped>
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-style {
  max-width: 190px !important;
}
.text-style-chained {
  max-width: 160px !important;
}
.zg-text-control::v-deep label {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: bold;
}
.pairs-bg {
  background-color: #f9f9f9;
}
</style>