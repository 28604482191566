import { render, staticRenderFns } from "./PredefinedRecordMenuDelegate.vue?vue&type=template&id=4ca049d0&scoped=true&"
import script from "./PredefinedRecordMenuDelegate.vue?vue&type=script&lang=js&"
export * from "./PredefinedRecordMenuDelegate.vue?vue&type=script&lang=js&"
import style0 from "./PredefinedRecordMenuDelegate.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./PredefinedRecordMenuDelegate.vue?vue&type=style&index=1&id=4ca049d0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ca049d0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VCheckbox,VFlex,VHover,VIcon,VLayout,VSpacer,VSwitch})
